<template >
    <div class="aa-lectura mb-3" v-if="noticiaDetalle" >
        <div class="aa-lectura-img" :style="'background-image: url('+noticiaDetalle.portada+')'">
        </div>
        

        
        <div class="noticia-card">
            <div class="aa-lectura-txt aa-text-h2 mb-1 mt-3 text-start mt-2">
                {{noticiaDetalle.titulo}}
            </div>
            <div class="aa-lectura-doc text-start" v-html="noticiaDetalle.cuerpo"></div>
            
            <div class="noticia-multimedia mt-3" v-if="noticiaDetalle.multimedia">
                <video v-if="noticiaDetalle.multimedia[0].mime == 'video/mp4'" controls preload="auto" playsinline>
                    <source :src="noticiaDetalle.multimedia[0].url" type="video/mp4">
                </video>
            </div>

            <div class="noticia-doc">
                <DescargaDocumento :documentos="noticiaDetalle.documentos" :titulo="'Documentos'" class="mt-2" />
            </div>

        </div>
    </div>
    <div v-else>
        <PrensaSk />
    </div>
</template>


<script>
import { useRoute } from 'vue-router'
import { watch, defineAsyncComponent } from 'vue';


import useNoticias from '../composables/useNoticias'
import PrensaSk from '../components/skeleton/PrensaSk.vue'

export default {
    components: {
        PrensaSk,
        DescargaDocumento: defineAsyncComponent(() => import("../components/Area/DescargaDocumento.vue")),
    },
    setup(){
        const route = useRoute()
        const { noticiaDetalle, leeNoticia } = useNoticias()
        leeNoticia(route.params.id)
        
        watch(
            () => route.params.id,
            () => leeNoticia(route.params.id)
        )

        return{
            noticiaDetalle
        }
    }
}
</script>

<style lang="sass" scoped>

.aa-lectura-img
    height: 220px
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    overflow: hidden
    margin-top: 96px
    @include breakpoint(md)
        height: 390px

.noticia-card
    width: 85vw
    padding: 10px
    background-color: #fff
    margin: 0 auto
    position: relative
    top: -60px
    @include breakpoint(md)
        width: 80vw

    .noticia-multimedia
        padding: 20px
        video
            width: 100%
            max-height: 480px
            max-width: 100%
</style>