import { useStore } from "vuex"
import { computed, ref } from "vue"

import { marked } from 'marked'

import publicApi from "@/api/publicApi"

import defaultImg from '@/assets/img/ESCUDO_FRAY.png'
import defaultHeaderImg from '@/assets/img/header_noimg.png'

const useNoticias = () => {
    const store = useStore()
    const isLoading = ref(false)
    const noticiaDetalle = ref()

    const noticiasList = ref([])
    const inicioNoticias = ref(0)
    const finNoticias = ref(5)
    const totalNoticias = ref(0)
    const primeraNoticia = ref(null)

    const scrollComponent = ref(null)

    const noticiasHome = async() => {
        const data = await store.dispatch('fray/cargaUltimasCinco')
        return data
    }
    

    const noticias = async() => {
        const data = await store.dispatch('fray/cargaNoticias')
        return data
    }

    const leeNoticia = async(id) => {
        if (!id) return
        isLoading.value = true
        noticiaDetalle.value = null

        try {
            const { data } = await publicApi.get(`noticias/${id}?populate[portada][fields]=url&populate[media]&populate[medias][fields]=url, mime&populate[documentos][fields][0]=url,caption`)
            isLoading.value = false
            noticiaDetalle.value = {
                id: data.data.id,
                titulo: data.data.attributes.titulo,
                cuerpo: marked(data.data.attributes.cuerpo),
                portada: (data.data.attributes.portada.data) ? data.data.attributes.portada.data.attributes.url : defaultHeaderImg,
                documentos: (data.data.attributes.documentos) ? data.data.attributes.documentos.data : null,
                multimedia: (data.data.attributes.medias.data) ? data.data.attributes.medias.data.map( m => {
                    return {
                        url: m.attributes.url,
                        mime: m.attributes.mime
                    }
                }) : null
            }
        } catch (error) {
            console.log(error)
        }
    }

    const cargaNoticias = async() => {
        isLoading.value = true
        try{
            const { data } = await publicApi.get(`noticias?sort=createdAt:desc&populate[portada][fields][0]=url&pagination[start]=${inicioNoticias.value}&pagination[limit]=${finNoticias.value}`)
            
            const tratadas = data.data.map( n => {
                return {
                id: n.id,
                titulo: n.attributes.titulo,
                cuerpo: marked(n.attributes.cuerpo),
                portada: (n.attributes.portada.data) ? n.attributes.portada.data.attributes.url : defaultImg,
                }
            })
            
            if(primeraNoticia.value === null){
                primeraNoticia.value = tratadas.shift()
            }
            

            noticiasList.value.push(...tratadas)
            isLoading.value = false
            totalNoticias.value = data.meta.pagination.total
        }catch (error){
            console.log(error);
        }
    }

    const cargaMas = async () => {
        if (finNoticias.value >= totalNoticias.value) return
        inicioNoticias.value += 5
        finNoticias.value +=5
        cargaNoticias()
    }

    return {
        cargaNoticias,
        cargaMas,
        leeNoticia,
        noticias,
        noticiasHome,
        finNoticias,
        inicioNoticias,
        isLoading,
        noticiaDetalle,
        primeraNoticia,
        noticiasList,
        totalNoticias,
        recientes: computed(() => store.state.fray.ultimasCinco),
        scrollComponent
    }

}


export default useNoticias